const BaseUrl = {
    LOGIN_AND_REGISTER: process.env.VUE_APP_BASE_API,
    PHONE_CODE: process.env.VUE_APP_BASE_API,
    REGISTER: process.env.VUE_APP_BASE_API,
    ARTICLE_MANAGEMENT: process.env.VUE_APP_BASE_API,
    UPLOAD:  process.env.VUE_APP_BASE_API,
    GET_PHONE_CODE: process.env.VUE_APP_BASE_API,
    SUBMIT_CONSULT_INFO: process.env.VUE_APP_BASE_API,
}

export default BaseUrl;
