import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        redirect: "/index",
        children: [
            {
                path: "/index",
                component: () => import("../views/home/Index.vue")
            },
            {
                path: "/home",
                component: () => import ("../views/home/Index.vue")
            },
            {
                path: "/UserPrivacyAgreement",
                component: () => import ("../views/UserPrivacyAgreement.vue")
            },
            {
                path: "/activity",
                component: () => import("../views/activity/Activity.vue")
            },
            {
                path: "/events",
                component: () => import ("../views/events/Events.vue")
            },
            {
                path: "/understand",
                component: () => import("../views/understand/Understand.vue")
            },

           /* {
                path: "/community",
                component: () => import("../views/community/Community.vue"),
            },
            {
                path: '/community/worksDetail',
                component: () => import('../views/community/WorksDetail.vue'),
            },
            {
                path: "/user",
                component: () => import("../views/user/User.vue"),
                redirect: "user/setUserInfo",
                children: [
                    {
                        path: "worksList",
                        component: () => import("../views/user/children/WorksList.vue"),

                    },
                    {
                        path: "setUserInfo",
                        component: () => import("../views/user/children/SetUserInfo.vue"),
                    },
                    {
                        path: "messageNotification",
                        component: () => import("../views/user/children/MessageNotification.vue"),
                    },
                    {
                        path: "securityCenter",
                        component: () => import("../views/user/children/SecurityCenter.vue"),
                    },
                ],
                meta: { requiresAuth: true }
            }*/
        ]
    },
/*    {
        path: "/consult",
        component: () => import("../views/consult.vue")
    },*/
    {
        path: "/pedestrianism",
        component: () => import("../views/pedestrianism.vue")
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const loggedIn = checkIfLoggedIn() // 你需要编写一个函数来检查用户是否已登录
    if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
        // 如果目标路由需要登录，且用户未登录，则阻止跳转，并重定向到登录页面
        next({
            path: '/index',
            query: { redirect: to.fullPath }
        })
    } else {
        next()
    }
})


export default router

function checkIfLoggedIn() {
    let user = localStorage.loginInfo ? JSON.parse(localStorage.loginInfo) : null
    if (user===null){
        return false
    }
    return true
}
