<template>
  <div class="home">
    <el-container>
      <el-header class="header" style="padding: 0 ;height: auto">
        <el-row :gutter="20" type="flex" justify="center" align="middle">
          <el-col class="hidden-sm-and-down el-col-lg-6 hidden-md-and-down">
            <img class="logo-img" src="/logo.png">
          </el-col>
          <el-col class="el-col-lg-13 el-col-lg-offset-3 el-col-md-15 el-col-sm-18  el-col-xs-0">
            <el-menu :default-active="$route.path" class="el-menu-demo"
                     active-text-color="rgba(130,160,252)"
                     text-color="rgba(255,255,255)"
                     background-color="rgba(255, 255, 255, 0)"
                     mode="horizontal" @select="handleSelect">
              <el-menu-item index="/Index">首页</el-menu-item>
              <el-menu-item index="/Activity">活动体系</el-menu-item>
              <el-menu-item index="/Events">赛事动态</el-menu-item>
<!--              <el-menu-item index="/Community">凡恩社区</el-menu-item>-->
              <el-menu-item index="/Understand">了解凡恩</el-menu-item>
            </el-menu>
          </el-col>
<!--          <el-col class="el-col-lg-6 el-col-md-4 el-col-sm-6" style="display: flex; align-items: center;">
            <el-button v-if="userInfo===null" type="primary" @click="showLoginAndReg" round>登录/注册</el-button>
            <el-dropdown v-else>
              <el-avatar fit="fill"
                         :src=userInfo.avatar>
              </el-avatar>
              <el-dropdown-menu slot="dropdown">
                <div class="user-info">
                  <el-row>
                    <el-col :span="6">
                      <el-avatar style="margin-left: 5px" fit="fill" :size="50" :src=userInfo.avatar></el-avatar>
                    </el-col>
                    <el-col :span="10">
                      <p>{{ userInfo.nickName }}</p>
                    </el-col>
                    <el-col style="display: flex; align-items: center" :span="8">
                      <a style="text-decoration: none;color: #777777;" href="javascript:void (0)" @click="logOut">
                        退出
                      </a>
                      <el-button style="padding: 0; border: 0" icon="el-icon-my-logOut" circle
                                 @click="logOut"></el-button>
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: 10px;text-align: center">
                    <el-col :span="8">
                      <el-button icon="el-icon-my-userInformation" style="padding: 0 ;border: 0"
                                 @click="userSet"></el-button>
                      <h5>个人资料</h5>
                    </el-col>
                    <el-col :span="8">
                      <el-button icon="el-icon-my-userArticle" style="padding: 0; border: 0"
                                 @click="userWorksList"></el-button>
                      <h5>我的帖子</h5>
                    </el-col>
                    <el-col :span="8">
                      <el-button icon="el-icon-my-submitArticle" style="padding: 0;border: 0"
                                 @click="openSubmitWork"></el-button>
                      <h5>发布作品</h5>
                    </el-col>
                  </el-row>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>-->
          <el-col class="el-col-xs-24 hidden-sm-and-up">
            <el-menu
                :default-active="$route.path"
                class="el-menu-vertical-demo"
                :label="showLabel"
                active-text-color="rgba(130,160,252)"
                text-color="rgba(255,255,255)"
                background-color="rgba(255, 255, 255, 0)"
                @close="handleClose"
                @select="handleSelect">
              <el-submenu index="1">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>导航</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/Index">首页</el-menu-item>
                  <el-menu-item index="/Activity">活动体系</el-menu-item>
                  <el-menu-item index="/Events">赛事动态</el-menu-item>
<!--                  <el-menu-item index="/Community">凡恩社区</el-menu-item>-->
                  <el-menu-item index="/Understand">了解凡恩</el-menu-item>

                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-col>

        </el-row>
      </el-header>

      <el-main class="main" style="padding: 0 ;">
        <el-backtop :right="20" :bottom="220">
          <el-button id="go-to-top" circle icon="el-icon-my-home-go-to-top"/>
        </el-backtop>
        <router-view/>

        <SubmitWork ref="SubmitWorkShow"/>
        <ChatWindow/>
        <!--        <MarketingSidebar ref="marketingSidebar"/>-->
      </el-main>

      <el-footer class="footer" style="height: 230px; padding: 0; bottom: 0">
        <div class="footer-div">
          <el-row>
            <el-col :span="8">
              <div class="customer">
                <img class="hidden-md-and-down" src="/logo.png"
                     style="display: block;">
                <img class="hidden-sm-and-down" src="/customerService.png" style="width: 64px;height: 64px">
                <div class="customer-phone">
                  <h3 class="customer-text">客服电话</h3>
                  <h3 class="customer-text">17582990758</h3>
                </div>
                <!--                <p style="
                                font-size: 14px;
                                color: #FFFFFF;
                                line-height: 16px;
                                text-align: center">
                                  太原市小店区长风街与平阳路交叉口长风大厦20层
                                </p>-->
              </div>
            </el-col>
            <el-col :span="11">
              <div style="padding: 20px">
                <p class="footer-text-title">版权信息</p>
                <p class="footer-text">版权所有：太原凡恩教育科技有限公司</p>
                <p class="footer-text">总部地址：太原市小店区长风街与平阳路交叉口长风大厦20层</p>
                <p class="footer-text">
                  <a href="https://beian.mps.gov.cn/#/query/webSearch?code=14010502990182" rel="noreferrer" target="_blank"><img style="width: 16px;height: 16px"
                      src="/备案图标.png" alt="">晋公网安备14010502990182</a>
                  <a href="https://beian.miit.gov.cn/"
                     target="_blank">晋ICP备2024043805号-2</a>
                </p>
              </div>
            </el-col>
            <el-col :span="5">
              <div
                  style="display: flex; padding-top: 20px;width: auto;height: 151px;text-align: center;flex-direction: column;align-items:center">
                <p style=" font-size: 16px;
                 color: #FFFFFF;
                 margin: 0;
                 text-align: center;
                ">微信公众号</p>
                <img style="width: 38% ;height: auto; float: left;border-radius: 20px;margin: 0;z-index: 900;"
                     src="/weiChatCode.png">
              </div>
            </el-col>
          </el-row>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

// @ is an alias to /src
import PhotoWall from "@/components/PhotoWall.vue";
import LoginAndReg from "@/components/logInAndReg/LoginAndReg.vue";
import SubmitWork from "@/components/community/SubmitWork.vue";
import {getToken, removeToken} from "@/utils/auth";
import {userLogOut} from "@/store/modules/user";

export default {
  name: 'HomeView',
  components: {SubmitWork, PhotoWall, LoginAndReg},
  data() {
    return {
      userInfo: localStorage.loginInfo ? JSON.parse(localStorage.loginInfo) : null,
      showLabel: true,
      // userInfo: user.state.userId ? user.state : null,
      wd: '',

      loginAndRegShow: false,
    }
  },

  methods: {
    handleSelect(key, keyPath) {
      if (key === key) {
        this.$router.push(key);
      }
    },
    handleClose(key, keyPath) {
      if (keyPath === 1) {

      }
    },

    logOut() {
      userLogOut();
    },
    showLoginAndReg() {
      this.$refs.LoginAndRegEvent.showPopup = true;
      this.loginAndRegShow = true;
    },
    openSubmitWork() {
      if (!getToken()) {
        this.$message.error("请先登录后在进行此操作")
        localStorage.removeItem('loginInfo');
        location.reload()
        return;
      }
      this.$refs.SubmitWorkShow.SubmitWorkDetailShow = true;
    },
    userSet() {
      this.$router.push('/user')
    },
    userWorksList() {
      this.$router.push('/user/worksList')
    },
  },

}
</script>
<style scoped>
.home {
  max-width: 100%;
}

.header {
  /*  background-image: url("/public/headerBackground.png");*/
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 60px;
  max-width: 100%;
  backdrop-filter: blur(.3rem);
  z-index: 999;
}

.main {
  background-image: url("/public/background.png");
  background-size: 100% auto;
  min-height: 100vh;
  max-width: 100%;
  margin-top: 0;
}

.footer {
  max-width: 100%;
}

.logo-img {
  width: auto;
  height: auto;
  padding-top: 7px;
  margin-left: 90px;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.footer-div {
  background: #0C0623;
  height: 100%;
  text-align: center;

}

.customer {
  padding: 20px;
  text-align: left;
}

.customer-phone {
  display: inline-block;
}

.customer-text {
  color: rgb(255, 255, 255);
  letter-spacing: 0;
  margin: 5px;
  text-align: left;
}

.footer-text-title {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.footer-text {
  font-size: 14px;
  color: #FFFFFF;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.user-info {
  min-width: 230px;
}

.user-info h5 {
  margin: 0;
  color: #777777;
}

/*.header-title{
  background-image: url("/public/imgs/home/login/loginHeaderBackground.png");
  height: 300px;
  width: 100%;
}*/
#go-to-top {
  background: linear-gradient(180deg, #3B4DFF 0%, #26BEF5 100%);
  border-width: 0;
}


</style>
